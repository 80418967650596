<template>
	<v-dialog v-model="model" persistent scrollable max-width="60vw" max-height="90vh" @close="$emit('close')">
		<v-card>
			<v-card-title class="secondary">
				<v-layout fluid align-center white--text>
					<v-flex v-t="'tasks.import.imported_data'" headline></v-flex>
					<v-spacer></v-spacer>
					<v-btn icon @click="$emit('close')">
						<v-icon class="white--text">close</v-icon>
					</v-btn>
				</v-layout>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-layout column>
						<v-data-table
							:headers="headers"
							:items="items"
							disable-initial-sort
							hide-actions
							:pagination.sync="pagination"
							class="elevation-1 scroll-y fill-height"
						>
							<template v-slot:items="props">
								<td>
									<v-edit-dialog :return-value.sync="props.item.code" large persistent :cancel-text="$t('cancel')" :save-text="$t('ok')">
										{{ props.item.code }}
										<template v-slot:input>
											<v-text-field v-model="props.item.code" :label="$t('edit')" single-line />
										</template>
									</v-edit-dialog>
								</td>
								<td>
									<v-edit-dialog :return-value.sync="props.item.date" large persistent :cancel-text="$t('cancel')" :save-text="$t('ok')">
										{{ getPrintedDateShort(props.item.date) }}
										<template v-slot:input>
											<v-menu
												v-model="props.item.dueDateMenu"
												:close-on-content-click="false"
												:nudge-right="40"
												large
												persistent
												transition="scale-transition"
												offset-y
												full-width
												min-width="290px"
											>
												<template v-slot:activator="{ on }">
													<v-text-field :value="props.item.date" :label="$t('due_date')" prepend-icon="event" readonly type="date" v-on="on"></v-text-field>
												</template>
												<v-date-picker
													v-model="props.item.date"
													:first-day-of-the-week="$firstDayOfTheWeek()"
													:locale="$i18n.locale"
													:min="today"
													@input="props.item.dueDateMenu = false"
												></v-date-picker>
											</v-menu>
										</template>
									</v-edit-dialog>
								</td>
								<td>
									<v-edit-dialog :return-value.sync="props.item.account_title" large persistent :cancel-text="$t('cancel')" :save-text="$t('ok')">
										{{ props.item.account_title }}
										<template v-slot:input>
											<v-text-field v-model="props.item.account_title" :label="$t('edit')" single-line />
										</template>
									</v-edit-dialog>
								</td>
								<td>
									<v-edit-dialog :return-value.sync="props.item.reference" large persistent :cancel-text="$t('cancel')" :save-text="$t('ok')">
										{{ props.item.reference }}
										<template v-slot:input>
											<v-text-field v-model="props.item.reference" :label="$t('edit')" single-line />
										</template>
									</v-edit-dialog>
								</td>
								<td>
									<v-edit-dialog :return-value.sync="props.item.entry_title" large persistent :cancel-text="$t('cancel')" :save-text="$t('ok')">
										{{ props.item.entry_title }}
										<template v-slot:input>
											<v-text-field v-model="props.item.entry_title" :label="$t('edit')" single-line />
										</template>
									</v-edit-dialog>
								</td>
								<td class="text-xs-right">
									<v-edit-dialog :return-value.sync="props.item.debit" large persistent :cancel-text="$t('cancel')" :save-text="$t('ok')">
										{{ props.item.debit }}
										<template v-slot:input>
											<v-text-field v-model="props.item.debit" :label="$t('edit')" style="text-align: right !important" prefix="€" single-line type="number" />
										</template>
									</v-edit-dialog>
								</td>
								<td class="text-xs-right">
									<v-edit-dialog :return-value.sync="props.item.credit" large persistent>
										{{ props.item.credit }}
										<template v-slot:input>
											<v-text-field v-model="props.item.credit" :label="$t('edit')" style="text-align: right !important" prefix="€" single-line type="number" />
										</template>
									</v-edit-dialog>
								</td>
							</template>
						</v-data-table>
						<v-flex shrink>
							<v-layout justify-center align-center>
								<v-pagination v-if="pages > 1" v-model="pagination.page" total-visible="5" :length="pages" />
							</v-layout>
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-layout row justify-end align-center>
					<v-btn flat color="primary" @click="$emit('close')">
						<v-icon>close</v-icon>
						<span v-t="'close'" class="ml-2" />
					</v-btn>
				</v-layout>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerActionImportedData',
	mixins: [TasksManagerModuleGuard],
	props: {
		showImportedData: {
			required: true,
			type: Boolean
		},
		items: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			today: new Date().toISOString(),
			headers: [
				{
					text: this.$t('tasks.import.table.headers.code'),
					align: 'left',
					sortable: true,
					value: 'code'
				},
				{
					text: this.$t('tasks.import.table.headers.date'),
					align: 'left',
					sortable: true,
					value: 'date'
				},
				{
					text: this.$t('tasks.import.table.headers.account_title'),
					align: 'left',
					sortable: true,
					value: 'account_title'
				},
				{
					text: this.$t('tasks.import.table.headers.reference'),
					align: 'left',
					sortable: true,
					value: 'reference'
				},
				{
					text: this.$t('tasks.import.table.headers.entry_title'),
					align: 'left',
					sortable: true,
					value: 'entry_title'
				},
				{
					text: this.$t('tasks.import.table.headers.debit'),
					align: 'center',
					sortable: true,
					value: 'debit'
				},
				{
					text: this.$t('tasks.import.table.headers.credit'),
					align: 'center',
					sortable: true,
					value: 'credit'
				}
			],
			pagination: {}
		}
	},
	computed: {
		pages: function () {
			let result = 0
			if (this.pagination.rowsPerPage != null && this.pagination.totalItems != null) {
				result = Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
			}
			return result
		},
		model: {
			get: function () {
				return this.showImportedData
			},
			set: function () {
				// Do nothing
			}
		}
	},
	created: function () {
		this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
		this.items.forEach(item => (item.dueDateMenu = false))
	},
	mounted: function () {
		this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
	},
	methods: {
		getPrintedDateTiny: function (date) {
			return this.$d(new Date(date), 'tiny')
		},
		getPrintedDateShort: function (date) {
			return this.$d(new Date(date), 'short')
		}
	}
}
</script>
